<template>
  <b-card class="register-card mb-3">
    <!-- <b-row class="pl-3 pr-3 text-center">
          <div class="w-100">
            <Steps
              :stepsArray="stepsArray"
              :config="config"
              :length="config.length"
            />
          </div>
        </b-row>
         -->
    <b-container v-for="(step, stepIndex) of config" :key="stepIndex">
      <div
        :key="stepIndex"
        :title="step.title"
        v-if="stepIndex === currentStep"
      >
        <small v-if="!noButtons" class="text-muted"
          >Step {{ currentStep + 1 }} of {{ config.length }}</small
        >
        <hr v-if="!noButtons" />
        <h1 class="mb-0">{{ step.title }}</h1>

        <b-row class="mt-4 mb-4">
          <template v-for="(el, elementIndex) of step.elements">
            <b-col
              :key="elementIndex"
              :cols="el.sizes.xs"
              :sm="el.sizes.sm"
              :md="el.sizes.md"
              :lg="el.sizes.lg"
            >
              <b-form-group :label="el.label" v-if="isVisible(el.hidden)">
                <b-form-input
                  :name="el.field"
                  v-if="el.type == 'text'"
                  :ref="el.field"
                  :type="el.type"
                  :placeholder="el.placeholder"
                  v-validate="el.rules"
                  :data-vv-as="el.label"
                  v-model="value[el.field]"
                  :disabled="isDisabled"
                ></b-form-input>
                <b-form-input
                  :name="el.field"
                  v-if="el.type == 'hidden'"
                  :ref="el.field"
                  :type="el.type"
                  style="display: none;"
                  v-model="value[el.field]"
                  :disabled="isDisabled"
                ></b-form-input>
                <b-form-tags
                  :name="el.field"
                  :ref="el.field"
                  v-if="el.type == 'tags'"
                  v-validate="el.rules"
                  :data-vv-as="el.label"
                  :placeholder="el.placeholder"
                  v-model="value[el.field]"
                  :disabled="isDisabled"
                ></b-form-tags>
                <b-form-select
                  :name="el.field"
                  :ref="el.field"
                  v-if="el.type === 'country'"
                  v-model="value[el.field]"
                  :placeholder="el.placeholder"
                  v-validate="el.rules"
                  :data-vv-as="el.label"
                  :options="countries"
                  :disabled="isDisabled"
                ></b-form-select>
                <b-form-textarea
                  :name="el.field"
                  v-if="el.type == 'textarea'"
                  :ref="el.field"
                  :type="el.type"
                  :placeholder="el.placeholder"
                  v-validate="el.rules"
                  :data-vv-as="el.label"
                  v-model="value[el.field]"
                  :disabled="isDisabled"
                ></b-form-textarea>
                <b-form-checkbox-group
                  :name="el.field"
                  v-if="el.type === 'checkbox'"
                  :ref="el.field"
                  v-model="value[el.field]"
                  :placeholder="el.placeholder"
                  v-validate="el.rules"
                  :data-vv-as="el.label"
                  :options="el.values"
                  stacked
                  :disabled="isDisabled"
                ></b-form-checkbox-group>
                <b-form-radio-group
                  :name="el.field"
                  :ref="el.field"
                  v-if="el.type === 'radio'"
                  v-model="value[el.field]"
                  :placeholder="el.placeholder"
                  v-validate="el.rules"
                  :data-vv-as="el.label"
                  :options="el.values"
                  stacked
                  :disabled="isDisabled"
                ></b-form-radio-group>
                <b-form-select
                  :name="el.field"
                  :ref="el.field"
                  v-if="el.type === 'dropdown'"
                  v-model="value[el.field]"
                  :placeholder="el.placeholder"
                  v-validate="el.rules"
                  :data-vv-as="el.label"
                  :options="el.values"
                  :disabled="isDisabled"
                ></b-form-select>

                <!-- <b-form-datepicker
                  :name="el.field"
                  :ref="el.field"
                  v-if="el.type === 'date' || el.type === 'datetime'"
                  v-model="value[el.field]"
                  :placeholder="el.placeholder"
                  :data-vv-as="el.label"
                  v-validate="el.rules"
                  :disabled="isDisabled"
                ></b-form-datepicker> -->
                <b-input-group
                  v-if="el.type === 'date' || el.type === 'datetime'"
                >
                  <b-form-input
                    :id="el.field"
                    :name="el.field"
                    :ref="el.field"
                    v-if="el.type === 'date' || el.type === 'datetime'"
                    v-model="value[el.field]"
                    :placeholder="el.placeholder"
                    :data-vv-as="el.label"
                    v-validate="el.rules"
                    :disabled="isDisabled"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      :name="el.field"
                      :ref="el.field"
                      v-if="el.type === 'date' || el.type === 'datetime'"
                      v-model="value[el.field]"
                      :placeholder="el.placeholder"
                      :data-vv-as="el.label"
                      v-validate="el.rules"
                      :disabled="isDisabled"
                      :aria-controls="el.field"
                      button-only
                      right
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>

                <b-row v-if="el.type == 'attachment'">
                  <a :href="value[el.field]" class="ml-3 mb-2 link" download>
                    {{ attachmentName }}</a
                  >
                </b-row>
                <input
                  v-if="el.type == 'attachment'"
                  v-validate="el.rules"
                  @change="onAttachmentChange($event, el.field)"
                  :name="el.field"
                  :accept="el.extensions"
                  type="file"
                  :id="el.field"
                  :ref="el.field"
                  :disabled="isDisabled"
                  hidden
                />
                <!-- Attachment -->

                <b-button
                  v-if="el.type == 'attachment'"
                  class="mr-3"
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-original-title="Attach file"
                  @click="chooseAttachment(el.field)"
                  >Add attachment
                </b-button>
                <ImageUpload
                  v-if="el.type == 'image'"
                  :url="value[el.field]"
                  :field="el.label"
                  v-validate="el.rules"
                  :ref="el.field"
                  @updateImage="updateImage(el.field, $event)"
                />
                <div v-if="el.type == 'workshop'">
                  <b-form-select
                    :name="el.field"
                    :ref="el.field"
                    v-model="value[el.field]"
                    v-validate="el.rules"
                    :data-vv-as="el.label"
                    :options="filteredWorkshops(el.values, value[el.field])"
                    :disabled="isDisabled"
                  ></b-form-select>
                  <div
                    v-html="getDescriptionForWorkshop(value[el.field])"
                  ></div>
                </div>
                <div v-if="el.type == 'static-text'" v-html="el.field"></div>

                <!-- <div v-if="errors.has(el.label)" class="alert-danger">
                      {{ errors.first(el.label) }}
                    </div> -->
                <div v-if="errors.has(el.field)" class="alert-danger">
                  {{ errors.first(el.field) }}
                </div>
              </b-form-group>
            </b-col>
          </template>
        </b-row>
      </div>
    </b-container>
    <b-button-toolbar v-if="!noButtons" justify>
      <b-button
        variant="primary"
        @click="previousStep"
        :class="previousButtonClass"
      >
        {{ $t("Previous") }}
      </b-button>

      <b-button v-if="isLastStep && !isDisabled" @click="doSubmit">
        <b-overlay :show="isSubmitting">
          {{ $t("Submit") }}
        </b-overlay>
      </b-button>
      <b-button variant="primary" v-else @click="nextStep">{{
        $t("Next")
      }}</b-button>
    </b-button-toolbar>
  </b-card>
</template>
<script>
import Vue from "vue";
import { translateSetting } from "@/utils/translated-setting.util";
import Steps from "@/components/Steps.vue";
import ImageUpload from "@/components/admin/ImageUpload.vue";
import axios from "@/plugins/axios.plugin";
import countries from "@/shared/countries.js";
import WorkshopsService from "@/services/WorkshopsService";

export default {
  props: ["config", "value", "noButtons", "register", "submitted", "disabled"],
  components: {
    // Steps,
    ImageUpload,
  },
  data() {
    return {
      currentStep: 0,
      stepsArray: [],
      elementTypesWithOptions: ["checkbox", "radio", "dropdown"],
      showEdit: false,
      isValid: true,
      uploadingFile: false,
      attachmentName: "",
      countries: countries,
      workshops: [],
      isSubmitting: false,
    };
  },
  async created() {
    this.initializeSteps();
    this.workshops = await WorkshopsService.fetchAllUnpaginated();
  },
  methods: {
    workshopIsFull(workshop) {
      if (!workshop.limit_seats) {
        return false;
      }
      if (workshop.number_of_seats > workshop.reserved_reservations_count) {
        return false;
      }
      return true;
    },
    filteredWorkshops(workshopIds, selectedWorkshopId) {
      const workshops = [];
      for (const workshopId of workshopIds) {
        const workshop = this.workshops?.find((w) => w.id === workshopId);
        if (
          workshop &&
          (!this.workshopIsFull(workshop) || workshop.id === selectedWorkshopId)
        ) {
          workshops.push(workshop);
        }
      }
      return workshops.map((w) => {
        return { text: this.translate(w.title), value: w.id };
      });
    },
    getDescriptionForWorkshop(workshopId) {
      const workshop = this.workshops?.find((w) => w.id === workshopId);
      if (!workshop) {
        return "";
      }
      return this.translate(workshop?.description);
    },
    isVisible(conditions) {
      if (!conditions) {
        return true;
      }
      const conditionArray = conditions.split("|");
      console.log(conditions);
      for (const condition of conditionArray) {
        if (this.evaluateCondition(condition)) {
          return true;
        }
      }
      return false;
    },
    evaluateCondition(condition) {
      const isInversed = condition.startsWith("hide:");
      const logic = condition.replace("hide:", "").replace("show:", "");
      const hasAmpersand = condition.includes("&");
      if (hasAmpersand) {
        const conditionArray = logic.split("&");
        for (const andCondition of conditionArray) {
          if (!this.evaluateOperation(andCondition, isInversed)) {
            return false;
          }
        }
        return true;
      } else {
        return this.evaluateOperation(logic, isInversed);
      }
    },
    evaluateOperation(logic, isInversed) {
      const operators = logic.split(",");
      if (operators.length !== 3) {
        return true;
      }

      let valueA = this.valueAndUserParameters[operators[0]];
      console.log(operators[0], valueA);
      if (typeof valueA == "string") {
        valueA = `"${valueA}"`;
      }
      const operator = operators[1];
      let valueB = operators[2];
      if (operator.startsWith(".")) {
        valueB = `(${valueB})`;
      }
      const result = eval(`${valueA}${operator}${valueB}`);
      console.log(`${valueA}${operator}${valueB}`);
      return isInversed ? !result : result;
    },
    chooseAttachment(name) {
      document.getElementById(name).click();
    },
    updateImage(field, newUrl) {
      Vue.set(this.value, field, newUrl);
    },
    async onAttachmentChange(event, el) {
      await this.uploadFile(event.target.files[0], el);
    },
    async uploadFile(file, field) {
      this.uploadingFile = true;
      let formData = new FormData();
      formData.append("file", file);
      const response = await axios.post("/files/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        responseType: "json",
      });
      this.fileUrl = process.env.VUE_APP_FILE_URL + response.data.url;
      this.value[field] = response.data.url;
      this.attachmentName = file.name;
      this.uploadingFile = false;
    },
    needsOptions(type) {
      return this.elementTypesWithOptions.includes(type);
    },
    translate(value) {
      return translateSetting(value, this.locale);
    },
    async nextStep() {
      const isValid = await this.$validator.validateAll();
      console.log(this.errors);
      if (isValid) {
        this.currentStep++;
        Vue.set(this.stepsArray, this.currentStep, "active");
      }
    },
    previousStep() {
      Vue.set(this.stepsArray, this.currentStep, "inactive");
      this.currentStep--;
      Vue.set(this.stepsArray, this.currentStep, "active");
    },
    async doSubmit() {
      this.isSubmitting = true;
      const isValid = await this.$validator.validateAll();
      if (isValid) {
        this.$emit("submit", this.value);
      }
      this.isSubmitting = false;
    },
    initializeSteps() {
      for (var i = 0; i < this.config.length; i++) {
        if (i == 0) {
          this.stepsArray[i] = "active";
        } else {
          this.stepsArray[i] = "inactive";
        }
      }
    },
  },
  computed: {
    locale() {
      return this.$root.$i18n.locale;
    },
    currentUser() {
      return this.$store.getters["Auth/currentUser"];
    },
    valueAndUserParameters() {
      return {
        ...this.currentUser.additional_info,
        ...this.currentUser.additional_info_form1,
        ...this.currentUser.additional_info_form2,
        ...this.currentUser.additional_info_form3,
        ...this.currentUser.additional_info_form4,
        ...this.value,
      };
    },
    previousButtonClass() {
      if (this.isFirstStep) {
        return "invisible";
      }
      return "";
    },
    isFirstStep() {
      return this.currentStep === 0;
    },
    isLastStep() {
      return this.currentStep === this.config.length - 1;
    },
    isDisabled() {
      return this.disabled ? true : false;
    },
  },
};
</script>
<style lang="scss">
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);

.registration-card-container {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 75vh; /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
}

.register-card {
  width: 100%;
  // max-width: 30rem;
  margin: 0 auto;
  vertical-align: center;
  // color: $text-color-header;
}

.submit-button-container {
  padding-top: 10px;
}

.link-privacy {
  color: $text-color-title;
}
.b-form-btn-label-control.form-control > .form-control {
  // margin: -35px 40px;
}
</style>
